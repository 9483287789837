import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import toast, { Toaster } from 'react-hot-toast';

const FloatButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #2563eb;
  color: white;
  padding: 16px 24px;
  border-radius: 50px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  border: 2px solid white;
  animation: bounce 2s infinite;

  &:hover {
    background-color: #1a1e7a;
    transform: scale(1.05);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.25);
  }

  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }

  @media (max-width: 768px) {
    padding: 14px 20px;
    font-size: 14px;
    bottom: 16px;
    right: 16px;
  }

  @media (max-width: 480px) {
    padding: 12px 18px;
    font-size: 14px;
    bottom: 12px;
    right: 12px;
  }
`;

const FreeGuideFloatButton: React.FC = () => {
  // 현재 경로 확인
  const currentPath = window.location.pathname;
  const isAllowedPath = ['/', '/service', '/service/book/detail'].includes(currentPath);

  if (!isAllowedPath) return null;

  const handleClick = () => {
    window.open("https://forms.gle/UzRcgXQ8NyGvXcVi8", "_blank");
    toast.success("신청 페이지로 이동합니다!");
  };

  return (
    <>
      <FloatButton onClick={handleClick}>
        📚 무료 가이드북 신청하기
      </FloatButton>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default FreeGuideFloatButton;