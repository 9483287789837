import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import toast, { Toaster } from 'react-hot-toast';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  width: 90%;
  max-width: 400px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 95%;
  }

  @media (max-width: 480px) {
    width: 98%;
  }
`;

const PopupImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 15px;
`;

const ApplyButton = styled.button`
  flex: 1;
  background-color: #202594;
  color: white;
  padding: 12px 0;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #1a1e7a;
  }
`;

const CloseButton = styled(ApplyButton)`
  background-color: white;
  color: #666;
  border: 1px solid #e0e0e0;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const CheckboxContainer = styled.div`
  padding: 15px 15px 0;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
  cursor: pointer;
`;

const CheckboxInput = styled.input`
  margin-top: 2px;
  margin-right: 8px;
`;

const PopupModal: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [doNotShowToday, setDoNotShowToday] = useState(false);

  useEffect(() => {
    const hidePopupUntil = localStorage.getItem('hidePopupUntil');
    const today = new Date().toISOString().split('T')[0];

    // 현재 경로 확인
    const currentPath = window.location.pathname;

    // 특정 경로에서만 팝업 활성화
    const isAllowedPath = ['/','/service', '/service/book/detail'].includes(currentPath);

    if (isAllowedPath && hidePopupUntil !== today) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, []);

  const handleApply = () => {
    window.open("https://forms.gle/UzRcgXQ8NyGvXcVi8", "_blank");
    closePopup();
    toast.success("신청 페이지로 이동합니다!");
  };

  const handleClose = () => {
    closePopup();
  };

  const handleCheckboxChange = () => {
    setDoNotShowToday(!doNotShowToday);
  };

  const closePopup = () => {
    setIsVisible(false);
    if (doNotShowToday) {
      const today = new Date().toISOString().split('T')[0];
      localStorage.setItem('hidePopupUntil', today);
    }
  };

  if (!isVisible) return null;

  return (
    <>
      <ModalOverlay onClick={handleClose}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <PopupImage 
            src="/images/popup.png" 
            alt="2025년 학종 가이드북"
            onClick={handleApply}
          />
          <CheckboxContainer>
            <CheckboxLabel>
              <CheckboxInput
                type="checkbox"
                checked={doNotShowToday}
                onChange={handleCheckboxChange}
              />
              오늘 하루 안 보기
            </CheckboxLabel>
          </CheckboxContainer>
          <ButtonContainer>
            <ApplyButton onClick={handleApply}>신청하기</ApplyButton>
            <CloseButton onClick={handleClose}>닫기</CloseButton>
          </ButtonContainer>
        </ModalContent>
      </ModalOverlay>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default PopupModal;
